// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

import "stylesheets/website" // Add this line

import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
// const images = require.context('../images', true)

Turbolinks.scroll = {};

document.addEventListener("turbolinks:load", ()=> {

  const elements = document.querySelectorAll("[data-turbolinks-scroll]");

  elements.forEach(function(element){

    element.addEventListener("click", ()=> {
      Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
    });

    element.addEventListener("submit", ()=> {
      Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
    });

  });

  if (Turbolinks.scroll['top']) {
    document.scrollingElement.scrollTo(0, Turbolinks.scroll['top']);
  }

  Turbolinks.scroll = {};

  const newEmailForms = document.querySelectorAll('.new-email')
  // const emailField = document.querySelector('#hero_email')
  // const continueSignupLink = document.querySelector('.continue-signup')
  // const submitButton = document.querySelector('#submit-button')
  // const successMessage =  document.getElementById('success-message')
  // const errorMessage = document.getElementById('error-message')

  if(newEmailForms.length > 0) {
    let open, close, toggle, refresh

    newEmailForms.forEach(form => {
      const emailField = form.querySelector('.email-field')
      const submitButton = form.querySelector('.submit-button')
      const successMessage =  form.querySelector('.success-message')
      const errorMessage = form.querySelector('.error-message')
      const continueSignupLink = form.querySelector('.continue-signup')

      const onSubmit = (data) => {
        // console.log('onSubmit', data)
        emailField.value = ''
        submitButton.disabled = false
        emailField.disabled = false
        continueSignupLink.classList.add('hidden')
        close()
      }

      const onClose = () => {
        // console.log('onClose')

        // if(emailField.value.length > 1) {
        //   continueSignupLink.classList.remove('hidden')
        // }
      }

      form.addEventListener('submit', (e) => {
        setTimeout(function () {
          submitButton.disabled = true
          emailField.disabled = true
          successMessage.classList.add('hidden')
          errorMessage.classList.add('hidden')
        }, 0)
      })

      form.addEventListener("ajax:success", (event) => {
        const [_data, _status, xhr] = event.detail;
        const { email } = _data
        ;({ open, close, toggle, refresh } = createPopup('wM6IdIVi', { hidden: { email }, onSubmit, onClose }))
        // console.log('ajax:success', event, _data, _status, xhr, email)

        open()

        successMessage.classList.remove('hidden')
        errorMessage.classList.add('hidden')
      })

      form.addEventListener("ajax:error", () => {
        successMessage.classList.add('hidden')
        errorMessage.classList.remove('hidden')
        submitButton.disabled = false
        emailField.disabled = false
      })


      form.querySelector('.continue-signup').addEventListener('click', (e) => {
        e.preventDefault()
        open()
      })
    })
  }
});
